<i18n>
  {
    "es": {
      "ALL_BRANDS": "Todas las marcas"
    }
  }
</i18n>

<template>
  <div class="lg:px-9">
    <div class="max-w-1400 mx-auto relative mt-10 lg:mt-14">
      <div
        class="overflow-hidden justify-center w-full pl-4 lg:pl-0 lg:pr-9 xl:px-0"
      >
        <div
          @click="handleChangeLeft(false)"
          v-if="isDesktop && leftArrow"
          class="h-10 w-10 grid place-items-center absolute top-0 bottom-0 -left-10 my-auto rounded-tr-md rounded-br-md bg-white bg-opacity-40 text-neutral-black-1 cursor-pointer"
        >
          <img
            src="@/assets/svg/icons/black/icons-left-arrow-light.svg"
            class="h-8"
          />
        </div>
        <div
          @scroll="isDesktop ? null : handleScroll($event)"
          ref="sliderContainer"
          class="inline-flex justify-start items-start mx-auto h-full py-0 pl-0 pr-4 translate-x-0 transition-all duration-500 w-full snap-x snap-mandatory"
          :class="isDesktop ? '' : 'overflow-x-scroll scrollbar-hidden'"
        >
          <component
            :is="item.component"
            :blok="item"
            v-for="(item, index) in blok.items"
            :key="index"
            class="lg:ml-1 lg:mr-2"
          />
          <NuxtLink
            :to="`/${locale}/marcas`"
            class="flex items-center snap-center justify-center rounded border-1 text-others-black flex-shrink-0 px-3 py-1.5 whitespace-nowrap ml-4 w-142 h-52 lg:h-65 lg:w-210"
          >
            <span class="text-xxs font-normal underline">{{
              t('ALL_BRANDS')
            }}</span>
          </NuxtLink>
        </div>

        <div
          @click="handleChangeRight(true)"
          v-if="isDesktop && rightArrow"
          class="h-10 w-10 grid place-items-center absolute top-0 bottom-0 -right-10 my-auto rounded-tl-md rounded-bl-md bg-white bg-opacity-40 text-neutral-black-1 cursor-pointer"
        >
          <img
            src="@/assets/svg/icons/black/icons-right-arrow-light.svg"
            class="h-8"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  blok: Object,
})

const { locale, t } = useI18n()

const { isDesktop } = useDevice()

const sliderContainer = ref(null)
const autoplay = ref(false)

const leftArrow = ref(false)
const rightArrow = ref(true)

const slide = () => {
  if (isDesktop) {
    handleChangeRight()
  } else {
    handleChangeSlideMobile()
  }
}

const activateInterval = () => {
  if (!autoplay.value) return
  interval = setInterval(() => {
    slide()
  }, 10000)
}

let interval
const maxScroll = computed(() => {
  if (!sliderContainer.value) return 0
  const child = sliderContainer.value.children[0]
  const childWidth = child.clientWidth
  return childWidth * sliderContainer.value.children.length + 1
})
onMounted(() => {
  activateInterval()
})

const handleScroll = (e) => {
  if (!sliderContainer.value) return
  clearInterval(interval)
  const containerWidth = sliderContainer.value.clientWidth
  const scrollWidth = sliderContainer.value.scrollWidth
  const scrollLeft = sliderContainer.value.scrollLeft

  actualTranslate.value = scrollLeft

  if (scrollLeft === 0) {
    leftArrow.value = false
  } else {
    leftArrow.value = true
  }

  if (scrollLeft + containerWidth === scrollWidth) {
    rightArrow.value = false
  } else {
    rightArrow.value = true
  }

  activateInterval()
}

const actualTranslate = ref(0)
const handleChangeRight = () => {
  if (!sliderContainer.value) return
  clearInterval(interval)
  const containerWidth = sliderContainer.value.clientWidth

  if (actualTranslate.value < maxScroll.value - containerWidth) {
    sliderContainer.value.style.transform = `translateX(-${
      actualTranslate.value + containerWidth
    }px)`
    actualTranslate.value = actualTranslate.value + containerWidth
  } else {
    sliderContainer.value.style.transform = `translateX(0px)`
    actualTranslate.value = 0
  }

  if (actualTranslate.value === 0) {
    leftArrow.value = false
  } else {
    leftArrow.value = true
  }

  if (actualTranslate.value < maxScroll.value - containerWidth) {
    rightArrow.value = true
  } else {
    rightArrow.value = false
  }

  activateInterval()
}

const handleChangeLeft = () => {
  if (!sliderContainer.value) return
  clearInterval(interval)
  const containerWidth = sliderContainer.value.clientWidth

  if (actualTranslate.value > 0) {
    sliderContainer.value.style.transform = `translateX(-${
      actualTranslate.value - containerWidth
    }px)`
    actualTranslate.value = actualTranslate.value - containerWidth
  } else {
    sliderContainer.value.style.transform = `translateX(${maxScroll.value}px)`
    actualTranslate.value = maxScroll.value
  }

  if (actualTranslate.value === 0) {
    leftArrow.value = false
  } else {
    leftArrow.value = true
  }

  if (actualTranslate.value < maxScroll.value - containerWidth) {
    rightArrow.value = true
  } else {
    rightArrow.value = false
  }

  activateInterval()
}

const handleChangeSlideMobile = () => {
  if (!sliderContainer.value) return
  const childWidth = sliderContainer.value.children[0].clientWidth

  if (actualTranslate.value < maxScroll.value) {
    sliderContainer.value.scrollTo({
      left: actualTranslate.value + childWidth,
      behavior: 'smooth',
    })
    actualTranslate.value = actualTranslate.value + childWidth
  } else {
    sliderContainer.value.scrollTo({
      left: 0,
      behavior: 'smooth',
    })
    actualTranslate.value = 0
  }

  if (actualTranslate.value === 0) {
    leftArrow.value = false
  } else {
    leftArrow.value = true
  }

  if (actualTranslate.value < maxScroll.value) {
    rightArrow.value = true
  } else {
    rightArrow.value = false
  }
}
</script>
